/* Plugin CSS */
.wpcf7-form label {
	width: 100%;
}
.wpcf7-form-control.wpcf7-submit {
    border-radius: 30px;
    border: 1px solid $Color2;
	background-color: $Color2;
	color: $White;
	padding: 12px 20px !important;
	font-family: $font-regular;
	line-height: 20px;
	font-size: 14px;
	min-width: 140px;

	&:hover,
	&:focus {
		color: $White;
	}
}
.wpcf7-form-control.wpcf7-text {
	background-color: $Fourth-Color;
	border: 1px solid $Fourth-Color;
    border-radius: 0;
    padding: .375rem .75rem;
	    width: 100%;
}
.wpcf7-form-control.wpcf7-textarea {
	background-color: $Fourth-Color;
	border: 1px solid $Fourth-Color;
    border-radius: 0;
    padding: .375rem .75rem;
	    width: 100%;
}