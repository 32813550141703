.embed-container { 
	height: 0;
	height: auto;
	max-width: 100%;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative; 

	iframe,
	object,
	embed { 
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
} 

