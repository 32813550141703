.related {
	background: $Color3;
	padding-bottom: 70px;
    padding-top: 70px;

    article { 
		background: $White;
    	border: 0 none;
    	bottom: 0;
		height: 100%;
		position: relative;

		&:hover, 
		&:focus {
			box-shadow: 0 6px 18px rgba(0,0,0,.18);
		}
    }

    .news-loop {
		color: $Color1;
		text-decoration: none;
		transition: .2s ease-in;

		&:hover, 
		&:focus {
			outline: 0;
		}

		.news {

			.desc {
				padding: 1.25rem;

				.author {
					margin: 0;
					font-size: 12px;
	    			text-transform: uppercase;
				}

				h3 { 
					font-size: 1.5rem;
					font-family: $font-medium;
				}

				.entry-summary {
					p {
						&:first-child {
							font-family: $font-light;
						}
						&:last-child {
							margin: 0;
							font-family: $font-bold;
						}
					}
				}
			}  
		}	
	}
}  

.articles {
	article {
		background: $White;
		border: 1px solid #efefef;
		bottom: 0;
		height: 100%;
		position: relative;

		&:hover, 
		&:focus {
			box-shadow: 0 6px 18px rgba(0,0,0,.18);
		}
	}
	.news-loop {
		color: $Color1;
		text-decoration: none;
		transition: .2s ease-in;

		&:hover, 
		&:focus {
			outline: 0;
		}

		.news {

			.desc {
				padding: 1.25rem;

				.author {
					margin: 0;
					font-size: 12px;
	    			text-transform: uppercase;
				}

				h3 {
					font-size: 1.5rem;
					font-family: $font-medium;
				}

				.entry-summary {
					p {
						&:first-child {
							font-family: $font-light;
						}
						&:last-child {
							margin: 0;
							font-family: $font-bold;
						}
					}
				}
			}  
		}	
	}
}