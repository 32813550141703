#footer {
    background-color: $Color1;
    color: $White;
    padding-top: 70px;
    position: relative;
}
#footer .footer-widgets {
    padding-bottom: 60px;
}  
#footer .footer-widgets p {
    margin-bottom: 2rem;
}
#footer .footer-widgets p a {
    text-decoration: none;
    color: $White;
}
#footer .footer-widgets ul.footer-navigation {  
    padding: 0;
    list-style: none;
    text-transform: uppercase;
}  
#footer .footer-widgets ul.footer-navigation li {
    margin-bottom: 2rem;
}   
#footer .footer-widgets ul.footer-navigation li a {
    padding: 0;
    line-height: 24px;
}
#footer .footer-widgets img {
    max-width: 230px;
}
#footer .footer-widgets img.icon {
    width: 45px;
    display: inline-block;
    margin-right: 1rem;
    position: relative;
}
#footer .footer-widgets img.icon.location { 
    bottom: 15px;
}
#footer .credits {
    border-top: 2px solid #343f52;
}

@media only screen and (min-width: 1200px) {
    #footer {
        padding-top: 120px;
    }
    #footer .footer-widgets .col-xl-1 .separator {
        border-left: 2px solid #343f52;
        height: 100%;
        position: relative;
        left: 38px;
    } 
}
@media only screen and (max-width: 991px) {
    #footer {
        padding-top: 60px;
    }
    #footer .footer-widgets {
        padding-bottom: 40px;
    }
}
@media only screen and (max-width: 767px) {
    #footer .footer-widgets p {
        margin-bottom: 1.5rem;
    }
}