.page-header {
	.error404 & {  
		text-align: center;
	    margin-bottom: 45px;
	}

	h1 {
		span {
			.error404 & {  
			    font-size: 120px;
			}
		}
	}
}