.main {

	.main-content {
		padding: 2rem 0 0.75rem; 

		@include media-breakpoint-up(lg) { 
			padding: 3rem 0; 
		}

		.sidebar-primary & {
			background-color: $White;
			box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
			padding: 20px;
		}
		.home & {
			background-color: $White;
			box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
			padding: 20px;
		}

		@include media-breakpoint-up(lg) {  
		    min-height: 320px;
		}   

		//margin-bottom: 2rem;
	}
}  



/*
 * Employees
*/
#employees {
	padding: 96px 0;
    background: #eee;
}
#employees .member {
	margin-bottom: 6.4rem; 
	cursor: pointer;
}
#employees .modal-body .col-md-8 p {
	letter-spacing: 1px;
    font-size: 1rem;
    font-family: $font-light;
    line-height: 1.5;
    color: #4c576b;
    margin-top: 1.25rem;
}
#employees .modal-body .col-md-4 p {
    font-size: 1rem;
    color: #4c576b;
}
#employees .modal-body .contact-info a {
	display: block;
    font-size: 1rem;
    font-family: $font-regular;
    margin-bottom: 0.5rem;
	text-decoration: none;
    color: #4c576b;
}
#employees .modal-body .contact-info a i {
	margin-right: 0.5rem;
}



/*
 * Service
*/
#service {
	background-color: $White;
	padding-bottom: 70px;
	padding-top: 70px;
}
#service .item {
	background-color: $Color3;
	border-radius: 8px;
	padding: 32px;
} 
#service .item h3 { 
	margin-bottom: 2rem;
}
#service .item p { 
	font-family: $font-light;
}
@media only screen and (max-width: 991px) {
	#service {
		padding-bottom: 30px;
		padding-top: 50px;
	}
	#service .item img {
		display: inline-block;
		max-width: 70px;
	}
	#service .item h3 { 
		display: inline-block;
		margin-left: 1rem;
	}
}



/*
 * Ervaringen
*/
#ervaringen {
	background-color: $Color3;
	padding-bottom: 165px;
	padding-top: 160px;	
}
#ervaringen h2 {
	margin-bottom: 24px;
}
#ervaringen .item {
	position: relative;
}
#ervaringen .carousel-control-next, 
#ervaringen .carousel-control-prev {
	position: relative;
	display: inline-block;
	min-width: 75px;
}
#ervaringen .carousel-item h4 {
	line-height: 32px;
	font-size: 24px;
}
#ervaringen img {
	//width: 55px !important;
   // position: relative;
   // bottom: 12px;
}
#ervaringen img.quote {
	width: 58px !important;
    bottom: 18px;
    margin-right: 2.5rem;
}
@media only screen and (max-width: 991px) {
	#ervaringen {
		padding-top: 70px;
	    padding-bottom: 60px;	
	}
	#ervaringen .item {
		margin-bottom: 2rem;
	}
}
@media only screen and (max-width: 575px) {
	#ervaringen .carousel-control-next, 
	#ervaringen .carousel-control-prev {
		
	}
}


/*
 * Customers
*/
#klanten {
	background-color: #0d1a31;
	background-image: url('../images/pattern-logo.png');
	background-repeat: no-repeat;
	background-position: left 7% bottom 15%;
	background-size: auto;
	background-size: 30%;
	padding-bottom: 160px;
	padding-top: 160px;
}
#klanten h2 {
	color: $White;
	letter-spacing: 0.02em;
}
#klanten p {
	color: $White;
	font-family: $font-light;
	font-size: 16px;
	letter-spacing: 0.01em;
	line-height: 24px;
}
#klanten h3 {
	border-bottom: 2px solid $Color4;
	padding-bottom: 25px;
	margin-top: 60px;
	color: $Color2;
	font-size: 160px;
	font-weight: 400;	  
	margin-bottom: 30px;
	line-height: 160px;
	letter-spacing: -0.06em;
}
#klanten h4 {
	border-bottom: 1px solid $Color4;
	padding-bottom: 25px;
	color: $White;
	font-size: 96px;
	line-height: 96px;
	margin-bottom: 30px;
	margin-top: 30px;
	letter-spacing: -0.06em;
}
#klanten h5 {
	color: #fff;
	font-size: 24px;
	font-weight: $font-light;
	line-height: 32px;
	letter-spacing: -0.01em;
}
@media only screen and (min-width: 992px) {
	#klanten h2 {
		font-size: 48px;
		line-height: 56px;
	}
	#klanten .col-lg-7 {
		padding-left: 32px;
	}
}
@media only screen and (max-width: 991px) {
	#klanten {
		background-position: left 5% top 5%;
		background-size: 70%;
		padding-bottom: 90px;
		padding-top: 70px;
	}
	#klanten img {
		margin-bottom: 1rem;
	}
	#klanten h3 {
		font-size: 100px;

	}
	#klanten h4 {
		font-size: 72px;
	}
}
@media (max-width: 1400px) and (min-width: 768px) { 
	
}



/*
 * CONTACT
*/
#contact {
	background-color: #fff;
	background-image: url(../images/pattern-logo2.png);
	background-repeat: no-repeat;
    background-position: bottom right;
	padding-top: 120px;
    padding-bottom: 210px;
}
#contact .container {
    position: relative;
    z-index: 1;
}
#contact h4 {
	color: #4c576b;
	font-family: $font-light;
	line-height: 1.5;
	margin-bottom: 2.5rem;
}
#contact a.btn-primary .bi-arrow-right-circle	 {
	position: relative;
    bottom: 1px;
    left: 5px;
	transition: .2s ease-in;
}
#contact a.btn-primary:hover .bi-arrow-right-circle {
	left: 10px;
}
#contact .img {
	background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    bottom: 0;
    position: absolute;
    left: 0;
    text-align: right;  
    top: 0;
	width: 55%;
	transform:scaleX(-1)
}
#contact .img:before {
    background: linear-gradient(180deg,rgba(255,255,255,.15) 0,rgba(0,0,0,0) 75%,rgba(0,0,0,0));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000",endColorstr="#4d000000",GradientType=0);
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
}

@media only screen and (max-width: 991px) {
	#contact {
		/* padding-top: 70px; */
		padding-top: 0px;
	    padding-bottom: 60px;
	}
  	#contact .img {
		background: none;
		width: 0;
	}
	#contact .img:before {
		display: none;   
	}
}
