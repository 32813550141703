// Grid settings
$main-lg-columns:       12;
$sidebar-lg-columns:    4;
   

// Color
$Black: 			#000000;
$White:				#FFFFFF;
$font-color:		#636363;

$Primary-Color: 	#f90;
$Secondary-Color: 	#4a4a50;
$Tertiary-Color: 	#ebebeb;
$Fourth-Color: 	#eeeeee;

$Color1: #0d1a31;
$Color2: #33a2f4;
$Color3: #F6F8FA;
$Color4: #2f405e;
$Color5: #eeeeee;


// Font Family
$font-default-lightregular: 'araboto-lightregular';
$font-default-normalregular: 'araboto-normalregular';
$font-default-mediumregular: 'araboto-mediumregular';

$font-black: 	'heeboblack';
$font-bold: 	'heebobold';
$font-extrabold:'heeboextrabold';
$font-light: 	'heebolight';
$font-medium: 	'heebomedium';
$font-regular: 	'heeboregular';
$font-thin: 	'heebothin';

