/*
 * Header
*/      
#header {
    background-color: $Color1;
    border-bottom: 1px solid #efefef;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 99;
}

.home #header {
    background: transparent;
    border: 0 none;
    position: absolute;
    border-bottom: 0 none !important;
}


@media only screen and (max-width: 991px) {
	#header .container {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
}



.navbar-brand {
  padding-bottom: 0.75rem;
  margin-top: 20px;
}
.navbar-nav .nav-item {
  &:last-child {
    a {
      i {
        bottom: 0 !important;
      }
    }
  }
}




/*
 * Logo
*/
img.dve-logo {
	max-height: 75px;
}




/* Icon 1 */
.animated-icon1, .animated-icon2, .animated-icon3 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.animated-icon1 span, .animated-icon2 span, .animated-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.animated-icon1 span {
  background: #e65100;
}

.animated-icon2 span {
  background: #e3f2fd;
}

.animated-icon3 span {
  background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
 top: 0px;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.animated-icon1.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.animated-icon1.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Icon 3*/

.animated-icon2 span:nth-child(1) {
  top: 0px;
}

.animated-icon2 span:nth-child(2), .animated-icon2 span:nth-child(3) {
  top: 10px;
}

.animated-icon2 span:nth-child(4) {
  top: 20px;
}

.animated-icon2.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%;
}

.animated-icon2.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%;
}

/* Icon 4 */

.animated-icon3 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3 span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3 span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  left: 8px;
}

.animated-icon3.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.animated-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 21px;
  left: 8px;
}






/*
 * NAVBAR
*/
nav ul li {
	padding: 0 0.5rem;
}
nav ul li a {
	color: $White !important;   
	font-family: $font-regular;
  font-size: 14px !important;
  line-height: 20px;

  .home & {
    color: $Color1 !important;   

    @include media-breakpoint-down(md) { 
      color: $White !important;   
    }
  }
}
nav ul li a.btn-primary {
	color: $White !important;
  border: 0 none;
  padding: 12px 20px !important;
  line-height: 20px;
}
nav ul li a.btn-primary .bi-arrow-right-circle {
	position: relative;
    bottom: 1px;
    left: 5px;
	transition: .2s ease-in;
}
nav ul li a.btn-primary:hover .bi-arrow-right-circle {
	left: 10px;
}
.navbar-toggler {
	border-color: $Color2;
	background: $Color2;
}
.navbar-toggler-icon {
	border-color: #fff;
}

@media only screen and (max-width: 991px) {
	.navbar-collapse {
		background-color: #0b1b36;
		padding: 1rem;
		border-top: 2px solid $Color2;
		border-bottom: 2px solid $Color2;
    margin-top: 7px;
	}
	nav ul li {
		width: 100%;
		text-align: center;
		margin: 4px 0;
		padding: 0;
	}
	nav ul li a {
		color: #fff !important;
		border-radius: 5px !important;
		background-color: $Color2;
		padding: 0.75rem 0 !important;
	}
	.navbar-toggler {
		margin-right: 1rem;
		padding: 10px;
	}
	.navbar-toggler .open {
		right: 2px;
    	bottom: 1px;
	}
}




