
/*
 * Banner
*/
.banner {
	background-color: #0b1b36;
	background-image: url('../images/pattern-logo.png');
	background-repeat: no-repeat;
	background-position: left -3% bottom;
	background-size: auto;
	padding-top: 320px;
    padding-bottom: 100px;
}
.banner .container {
    position: relative;
    z-index: 1;
}
.banner h1 {
	color: #33a2f4;
	margin-bottom: 2rem;
}
.banner h1 span {
	color: #fff;
}
.banner p {
	color: #fff;
	font-family: $font-light;
	margin-bottom: 2rem;
}
.banner a.btn-primary .bi-arrow-right-circle	 {
	position: relative;
    left: 5px;
	transition: .2s ease-in;
}
.banner a.btn-primary:hover .bi-arrow-right-circle {
	left: 10px;
}
.banner a.btn-transparent .bi-arrow-down-circle {
	margin-right: 5px;
	position: relative;
    bottom: 0px;
	transition: .2s ease-in;
}
.banner a.btn-transparent:hover .bi-arrow-down-circle {
	bottom: -5px;
}

.banner .img {
	background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    bottom: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
	width: 55%;
}

@media only screen and (max-width: 991px) {
	.banner {
		background-size: 60%;
		background-position: left bottom;
		padding-top: 100px;
	    padding-bottom: 40px;  
	}
  	.banner .img {
		background: none;
		width: 0;
	}
	.banner .img:before {
		display: none;   
	}
}



/*
 * BANNER BANNER
*/
#ABanner {
	background-color: #eee;
	padding-top: 90px;
    padding-bottom: 90px;
}
#ABanner .container {
    position: relative;
    z-index: 1;
}
#ABanner h4 {
	color: #4c576b;
	font-family: $font-light;
	line-height: 1.5;
	margin-bottom: 2.5rem;
}
#ABanner a.btn-primary .bi-arrow-right-circle	 {
	position: relative;
    bottom: 1px;
    left: 5px;
	transition: .2s ease-in;
}
#ABanner a.btn-primary:hover .bi-arrow-right-circle {
	left: 10px;
}
#ABanner .img {
	background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    bottom: 0;
    position: absolute;
    left: 0;
    text-align: right;  
    top: 0;
	width: 50%;
}
#ABanner .img:before {
    background: linear-gradient(180deg,rgba(255,255,255,.15) 0,rgba(0,0,0,0) 75%,rgba(0,0,0,0));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000",endColorstr="#4d000000",GradientType=0);
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
}

@media only screen and (max-width: 991px) {
	#ABanner {
		padding-top: 0px;
	    padding-bottom: 60px;
	}
  	#ABanner .img {
		background: none;
		width: 0;
	}
	#ABanner .img:before {
		display: none;   
	}
}
