.column-item {
	margin-top: 40px;

	@include media-breakpoint-up(lg) { 
		margin-top: 70px;
	}

	h2 {
		font-size: 36px;
		margin-bottom: 55px;
		position: relative;

		&:after {
			content: "";
		    height: 4px;
		    background: $Primary-Color;
		    width: 100px;
		    position: absolute;
		    bottom: -15px;
		    left: 0;
		    right: 0;
		    margin: 0 auto;
		}
	}
	.item {
		border: 1px solid $Tertiary-Color;
		margin: 0 0 30px;   
		position: relative;

		a {
			display: block;

			h3 {
				position: absolute;
			    top: 50%;
			    left: 0;
			    color: $White;
			    right: 0;
			    text-align: center;  
			    z-index: 1;
			    font-size: 32px;
			    width: 60%;  
			    transform: translateY(-50%);
			    margin: 0 auto;
			    border: 1px solid $White;
			    padding: 10px 10px 14px;
			    line-height: 1;
			}

			&:after {
				background-color: rgba(0,0,0,0.2);
				bottom: 0;
				content: "";
				left: 0;
				right: 0;
		    	position: absolute;
		    	top: 0;
			}

			@include media-breakpoint-down(lg) {  
				img {
					width: 100%;
				}
			}	
		}
	}
} 


.column-layout {
	margin-top: 40px;

	@include media-breakpoint-up(lg) { 
		margin-top: 70px;
	}

	h2 {
		font-size: 36px;
		margin-bottom: 55px;
		position: relative;

		&:after {
			content: "";
		    height: 4px;
		    background: $Primary-Color;
		    width: 100px;
		    position: absolute;
		    bottom: -15px;
		    left: 0;
		    right: 0;
		    margin: 0 auto;
		}
	}
	.item {
		border: 1px solid $Tertiary-Color;
		margin: 0 0 20px;

		.img {
			border-bottom: 1px solid $Tertiary-Color;    
			position: relative;

			@include media-breakpoint-down(lg) {  
				img {
					width: 100%;
				}
			}	
		}

		.desc {
			//box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
			box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.05);
			//padding: 15px 15px 20px;
			padding: 15px;
			text-align: center;

			h3 {
				margin-bottom: .5rem;
			}

			p {
				margin: 0;
			}

			.btn {
				margin-top: 12px;
			}
		}
	}
}  

.column-layout-2 {
	margin-top: 40px;

	h2 {
		background: $Primary-Color;
		color: $White;
		font-size: 28px;
		margin-bottom: 25px;
		padding: 10px 15px 15px;
		position: relative;
	}
	
	.item {
		border: 1px solid $Tertiary-Color;
		margin: 0 0 20px;

		.img {
			border-bottom: 1px solid $Tertiary-Color;

			@include media-breakpoint-down(lg) {  
				img {
					width: 100%;
				}
			}	
		}

		.desc {
			//box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
			box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.05);
			//padding: 15px 15px 20px;
			padding: 15px;
			text-align: center;

			h3 {
				color: $Primary-Color;
				margin-bottom: .5rem;
			}

			p {
				margin: 0;
			}

			.btn {
				margin-top: 12px;
			}
		}
	}
}  


.featured {
	.loop-featured {
   		border: 1px solid $Tertiary-Color;
		box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.05);
		margin-bottom: 25px;
	}
}
