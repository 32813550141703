body {
	color: $Color1;
	font-family: $font-regular;
	font-size: 14px;
}
section {
	background-color: $White;
	position: relative;
}
h1 {
	font-family: $font-regular;
	line-height: 56px;
	letter-spacing: -0.02em;
}
h2 {
	font-family: $font-regular;
	line-height: 56px;
	letter-spacing: -0.02em;
	color: $Color1;
}
h3 {
	font-family: $font-regular;
	line-height: 40px;
	letter-spacing: -0.02em;
	color: $Color1;
}
p {
	line-height: 24px;
}


.page-header h1 {
	@include media-breakpoint-up(lg) { 
		margin-bottom: 1.75rem;
	}
}

@media only screen and (min-width: 1200px) {
	h1 {
		font-size: 54px;
	}
	h2 {
		font-size: 48px;
	}
	h3 {
		font-size: 32px;
	}
}
@media only screen and (min-width: 992px) {
	.row>* {  
	    padding-right: 20px;
	    padding-left: 20px;
	}
	h1 {
		font-size: 3.2rem;
	}
	h2 {
		font-size: 3rem;
	}
}
@media only screen and (max-width: 991px) {
	.row>* {  
	    padding-right: 20px;
	    padding-left: 20px;
	}
}
