.btn,
input[type="submit"] {
	border-radius: 30px;
	background-color: $Color2;
	border-color: $Color2;
	color: $White;
	padding: 12px 20px !important;
	font-family: $font-regular;
	line-height: 20px;
	font-size: 14px;

	&:hover,
	&:focus {
		color: $White;
	}  
}  

.btn-transparent {
	color: $White;
} 


