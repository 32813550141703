.sidebar {
	.widgets {
		background-color: $White;
		box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
		padding: 20px;

		@include media-breakpoint-down(lg) {
			margin-top: 3rem;
		}

		h3 {
			margin-top: 1rem;
		}

		ul {
			padding-left: 18px;
		}
	}
}   